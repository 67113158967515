import { Component, OnInit } from '@angular/core';

import { DataSharingService } from 'src/services/exports';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  constructor(public _dataSharingService: DataSharingService) {}

  ngOnInit(): void {}
}
