import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularFireModule } from '@angular/fire';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CookiesComponent } from './cookies/cookies.component';
import { FooterComponent } from './footer/footer.component';
import { MaterialPrimengModule } from './material-primeng.module';
import { MenuBarComponent } from './menu-bar/menu-bar.component';
import { NgModule } from '@angular/core';
import { NgxCookieServiceModule } from '@uniprank/ngx-cookie-service';
import { PrimengModule } from 'src/app/primeng.module';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    MenuBarComponent,
    FooterComponent,
    CookiesComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    PrimengModule,
    MaterialPrimengModule,
    AngularFireModule.initializeApp(environment.firebase),
    NgxCookieServiceModule.forRoot(),
  ],
  exports: [MaterialPrimengModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
