import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DataSheetService {
  constructor(private db: AngularFirestore) {}

  getByName(name) {
    return this.db
      .collection('ficha_tecnica', (ref) => ref.where('nameGame', '==', name))
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a) => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
  }

  remove(nameGame) {
    this.db
      .collection('ficha_tecnica', (ref) =>
        ref.where('nameGame', '==', nameGame)
      )
      .get()
      .toPromise()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
        });
      });
  }

  put(ficha_tecnica, id = '') {
    let name = ficha_tecnica.nameGame;
    id = id || this.db.createId();
    ficha_tecnica.id = id;
    ficha_tecnica = JSON.stringify(ficha_tecnica);

    this.db
      .collection('ficha_tecnica')
      .doc(id)
      .set({ id: id, data: ficha_tecnica, nameGame: name });

    return id;
  }
}
