import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GamesService {
  constructor(private db: AngularFirestore) {}

  getAll() {
    return this.db
      .collection('games')
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      );
  }

  remove(nameGame) {
    this.db
      .collection('games', (ref) => ref.where('nameGame', '==', nameGame))
      .get()
      .toPromise()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
        });
      });
  }

  put(game, id = '') {
    id = id || this.db.createId();
    game.id = id;

    this.db.collection('games').doc(id).set(Object.assign({}, game));

    return id;
  }
}
