// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    projectId: 'ukiyo-e-y-videojuegos',
    appId: '1:975765590276:web:9d1211d0bb990b24488051',
    storageBucket: 'ukiyo-e-y-videojuegos.appspot.com',
    locationId: 'europe-west',
    apiKey: 'AIzaSyAiRzAeNM-rDm6JiM7YK59Uvm2zuP8pvPI',
    authDomain: 'ukiyo-e-y-videojuegos.firebaseapp.com',
    messagingSenderId: '975765590276',
    measurementId: 'G-CYQN4SDYXZ',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
