import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthorsService {
  constructor(private db: AngularFirestore) {}

  getAll() {
    return this.db
      .collection('authors')
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      );
  }

  getByName(name) {
    return this.db
      .collection('authors', (ref) => ref.where('nameAuthor', '==', name))
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a) => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
  }

  remove(nameAuthor) {
    this.db
      .collection('authors', (ref) => ref.where('nameAuthor', '==', nameAuthor))
      .get()
      .toPromise()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
        });
      });
  }

  put(author, id = '') {
    let name = author.nameAuthor;
    id = id || this.db.createId();
    author.id = id;
    author = JSON.stringify(author);

    this.db
      .collection('authors')
      .doc(id)
      .set({ id: id, data: author, nameAuthor: name });

    return id;
  }
}
