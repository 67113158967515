<footer>
  <div class="footer">
    <div class="textCenter w100">
      <span
        >Copyright © 2022
        <a href="https://www.maiderwallartist.com/" target="_blank"
          >Maider Véliz Ramas</a
        >&nbsp;|&nbsp;<a
          href="https://www.videogamesanimator.com/"
          target="_blank"
          >Videogames Animator</a
        ></span
      >
    </div>
    <div class="textCenter w100">
      <span
        >Web realizada por
        <a href="https://fullstacksa.com/" target="_blank"
          >Cristian Salcedo Rodríguez</a
        ></span
      >
    </div>
    <div class="textCenter w100">
      <span>
        <a href="#" [routerLink]="'/legal'">Aviso legal</a>&nbsp;|&nbsp;<a
          href="#"
          [routerLink]="'/cookies'"
          >Cookies</a
        ></span
      >
    </div>
  </div>
</footer>
