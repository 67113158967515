import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenubarModule } from 'primeng/menubar';
import { NgModule } from '@angular/core';
import { TableModule } from 'primeng/table';

@NgModule({
  declarations: [],
  imports: [
    CardModule,
    AccordionModule,
    CheckboxModule,
    InputTextareaModule,
    ButtonModule,
    TableModule,
    MenubarModule,
  ],
  exports: [
    CardModule,
    AccordionModule,
    CheckboxModule,
    InputTextareaModule,
    ButtonModule,
    TableModule,
    MenubarModule,
  ],
  providers: [],
})
export class PrimengModule {}
