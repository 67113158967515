import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UiService {
  constructor(private db: AngularFirestore) {}

  getByName(name) {
    return this.db
      .collection('ui', (ref) => ref.where('nameGame', '==', name))
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a) => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
  }

  remove(nameGame) {
    this.db
      .collection('ui', (ref) => ref.where('nameGame', '==', nameGame))
      .get()
      .toPromise()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
        });
      });
  }

  put(ui, id = '') {
    let name = ui[0].nameGame;
    id = id || this.db.createId();
    ui.id = id;
    ui = JSON.stringify(ui);

    this.db.collection('ui').doc(id).set({ id: id, data: ui, nameGame: name });

    return id;
  }
}
