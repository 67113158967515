import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AccordionModule } from 'primeng/accordion';
import { AppComponent } from './app.component';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { NgModule } from '@angular/core';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ScrollTopModule } from 'primeng/scrolltop';
import { SidebarModule } from 'primeng/sidebar';
import { TabViewModule } from 'primeng/tabview';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MenuModule,
    DialogModule,
    ReactiveFormsModule,
    FormsModule,
    AccordionModule,
    MatDialogModule,
    CardModule,
    MenubarModule,
    MatMenuModule,
    DividerModule,
    ProgressSpinnerModule,
    ScrollTopModule,
    TabViewModule,
    TooltipModule,
    SidebarModule,
    DynamicDialogModule,
    CarouselModule,
  ],
  exports: [
    CommonModule,
    MenuModule,
    DialogModule,
    ReactiveFormsModule,
    FormsModule,
    AccordionModule,
    MatDialogModule,
    CardModule,
    MenubarModule,
    MatMenuModule,
    DividerModule,
    ProgressSpinnerModule,
    ScrollTopModule,
    TabViewModule,
    TooltipModule,
    SidebarModule,
    DynamicDialogModule,
    CarouselModule,
  ],
  providers: [DialogService],
  bootstrap: [AppComponent],
})
export class MaterialPrimengModule {}
