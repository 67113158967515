import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DataSharingService {
  loading: boolean;

  constructor() {}

  isMobile() {
    let width =
      window?.innerWidth < window.outerWidth
        ? window?.innerWidth
        : window.outerWidth;
    if (width > 1035) {
      return false;
    } else {
      return true;
    }
  }

  trackByFn(index, item) {
    return index;
  }
}
