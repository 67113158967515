import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  user: any;

  constructor() {}

  isLocalStorage(): boolean {
    var localStorage;

    try {
      localStorage = window.localStorage;
      if (localStorage) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }

  isSessionStorage() {
    var sessionStorage;

    try {
      sessionStorage = window.sessionStorage;

      if (sessionStorage) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }

  setItem(item: string, value: string) {
    if (this.isLocalStorage()) {
      try {
        localStorage.setItem(item, value);
      } catch (e) {
        if (this.isQuotaExceeded(e)) {
          this.setItemSessionStorage(item, value);
        }
      }
    } else {
      this.setItemSessionStorage(item, value);
    }
  }

  setItemSessionStorage(item: string, value: string) {
    if (this.isSessionStorage()) {
      try {
        sessionStorage.setItem(item, value);
      } catch (e) {
        if (this.isQuotaExceeded(e)) {
        }
      }
    }
  }

  getItem(item: string) {
    if (localStorage.getItem(item)) {
      return localStorage.getItem(item);
    } else if (sessionStorage.getItem(item)) {
      return sessionStorage.getItem(item);
    } else if (this.user) {
      return this.user;
    }
  }

  removeItem(key: string) {
    localStorage.removeItem(key);
    sessionStorage.removeItem(key);
  }

  clear() {
    localStorage.clear();
    sessionStorage.clear();
  }

  isQuotaExceeded(e) {
    var quotaExceeded = false;
    if (e) {
      if (e.code) {
        switch (e.code) {
          case 22:
            quotaExceeded = true;
            break;
          case 1014:
            // Firefox
            if (e.name === 'NS_ERROR_DOM_QUOTA_REACHED') {
              quotaExceeded = true;
            }
            break;
        }
      } else if (e.number === -2147024882) {
        // Internet Explorer 8
        quotaExceeded = true;
      }
    }
    return quotaExceeded;
  }
}
