import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { Injectable, NgZone } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/auth';
import { DataSharingService } from './data-sharing.service';
import { LocalStorageService } from './exports';
import { Router } from '@angular/router';
import { User } from 'src/models/user.interface';
import firebase from 'firebase/app';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userData: any; // Save logged in user data
  rememberPassword: boolean;

  constructor(
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    private _localStorageService: LocalStorageService,
    private _dataSharingService: DataSharingService,
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {
    /* Saving user data in localstorage when
    logged in and setting up null when logged out */
    let $subscribe = this.afAuth.authState.subscribe((user) => {
      this.userData = JSON.parse(JSON.stringify(user));
      this._localStorageService.user = JSON.stringify(this.userData);
      this.SetUserData(user);

      $subscribe.unsubscribe();
    });
  }

  // Sign in with email/password
  SignIn(email, password) {
    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.SetUserData(result.user);
        this.ngZone.run(() => {
          this.router.navigate(['index']);
        });
        this.router.navigate(['/index']);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // Sign up with email/password
  SignUp(email, password) {
    this._dataSharingService.loading = true;
    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then(async (result) => {
        /* Call the SendVerificaitonMail() function when new user sign
        up and returns promise */
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = this._localStorageService.getItem('user')
      ? JSON.parse(this._localStorageService.getItem('user'))
      : null;
    return user !== null;
  }

  // Sign in with Google
  GoogleAuth() {
    return this.AuthLogin(new firebase.auth.GoogleAuthProvider());
  }

  // Auth logic to run auth providers
  AuthLogin(provider) {
    return this.afAuth
      .signInWithPopup(provider)
      .then((result) => {
        this.SetUserData(result.user);
        this.router.navigate(['/index']);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${user.uid}`
    );
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
    };

    this._localStorageService.setItem('user', JSON.stringify(userData));
    this._localStorageService.user = JSON.stringify(userData);

    return userRef.set(userData, {
      merge: true,
    });
  }

  // Sign out
  SignOut() {
    return this.afAuth.signOut().then(() => {
      this._localStorageService.clear();
      this._localStorageService.user = null;
      this.userData = null;
      this.router.navigate(['login']);
    });
  }
}
