import { Component, OnInit } from '@angular/core';

import { LocalStorageService } from 'src/services/exports';
import { MatDialog } from '@angular/material/dialog';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.scss'],
})
export class MenuBarComponent implements OnInit {
  items: MenuItem[];

  constructor(
    public _localStorageService: LocalStorageService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    let user = null;

    if (this._localStorageService.getItem('user')) {
      user = JSON.parse(this._localStorageService.getItem('user'));
    }

    user = user?.displayName
      ? user?.displayName?.split(' ')[0]
      : user?.email
      ? user?.email
      : null;

    this.items = [
      {
        label: 'Home',
        icon: 'pi pi-home',
        routerLink: ['/index', { skipLocationChange: true }],
      },
      {
        label: 'Análisis de juegos',
        icon: 'pi pi-prime',
        routerLink: '/analisis',
      },
      {
        label: 'Imágenes Ukiyo-e',
        icon: 'pi pi-images',
        routerLink: '/ukiyoe',
      },
      {
        label: 'Conclusiones',
        icon: 'pi pi-comments',
        routerLink: '/conclusiones',
      },
      {
        label: 'Bibliografía',
        icon: 'pi pi-book',
        routerLink: '/bibliografia',
      },
      {
        label: 'Artículo',
        icon: 'pi pi-file',
        routerLink: '/articulo',
      },
      {
        label: 'Mis webs',
        icon: 'pi pi-globe',
        items: [
          {
            label: 'Videogames Animator',
            url: 'https://www.videogamesanimator.com/',
            target: 'blank',
          },
          {
            label: 'Maider Wall Artist',
            url: 'https://www.maiderwallartist.com/',
            target: 'blank',
          },
        ],
      },
    ];
  }
}
