<div class="grdp" *ngIf="visible">
  <p>
    <strong
      >GDPR (General Data Protection Regulation - Reglamento General Protección
      de Datos)</strong
    >
    <br />
    <br />
    Utilizamos cookies propias y de terceros para obtener datos estadísticos de
    la navegación de nuestros usuarios y mejorar nuestros servicios. Si acepta o
    continúa navegando, consideramos que acepta su uso. Puede obtener más
    información aquí <a routerLink="/cookie">Política de Cookies.</a>
  </p>
  <br />
  <button
    pButton
    pRipple
    type="button"
    icon="pi pi-check"
    (click)="onGRDP()"
    class="p-button-rounded p-button-success"
  ></button>
</div>
