import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { CookieService } from '@uniprank/ngx-cookie-service';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss'],
})
export class CookiesComponent implements OnInit {
  cookieValue = 'UNKNOWN';
  visible: boolean = true;
  @Output() close: EventEmitter<any> = new EventEmitter();

  constructor(private _cookieService: CookieService) {}

  ngOnInit(): void {
    this.cookieValue = this._cookieService.get('Cookie-ukiyoe');

    if (this.cookieValue) {
      this.visible = false;
    } else {
      this.visible = true;
    }
  }

  onGRDP() {
    this.visible = !this.visible;
    this._cookieService.set('Cookie-ukiyoe', 'GDPR');
    if (this.visible) {
      this.close.emit(null);
    }
  }
}
