import { Component, OnInit } from '@angular/core';
import { DataSharingService, LocalStorageService } from 'src/services/exports';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    public _localStorageService: LocalStorageService,
    public _dataSharingService: DataSharingService
  ) {}

  ngOnInit(): void {}
}
