<div class="container">
  <div class="insideContainer">
    <div
      class="p-card mb1 mt1 h175px positionRelative overflowHidden flexCenter"
    >
      <img
        class="banner"
        src="../assets/header/banner.webp"
        alt="UKIYO-E EN LOS VIDEOJUEGOS"
      />
    </div>
    <div
      class="flexCenter w100 h100 spinner"
      *ngIf="_dataSharingService.loading"
    >
      <p-progressSpinner></p-progressSpinner>
    </div>
    <app-menu-bar></app-menu-bar>
    <router-outlet></router-outlet>
    <app-cookies></app-cookies>
  </div>
</div>
<app-footer></app-footer>
<p-scrollTop [threshold]="200"></p-scrollTop>
