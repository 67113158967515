import { RouterModule, Routes } from '@angular/router';

import { NgModule } from '@angular/core';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/index',
    pathMatch: 'full',
  },
  {
    path: 'index',
    loadChildren: () =>
      import('./index/index.module').then((m) => m.IndexModule),
  },
  {
    path: 'analisis',
    loadChildren: () =>
      import('./game-analisis/game-analisis.module').then(
        (m) => m.GameAnalisisModule
      ),
  },
  {
    path: 'bibliografia',
    loadChildren: () =>
      import('./bibliography/bibliography.module').then(
        (m) => m.BibliographyModule
      ),
  },
  {
    path: 'ukiyoe',
    loadChildren: () =>
      import('./ukiyoe/ukiyoe.module').then((m) => m.UkiyoeModule),
  },
  {
    path: 'conclusiones',
    loadChildren: () =>
      import('./conclussions/conclussions.module').then(
        (m) => m.ConclussionsModule
      ),
  },
  {
    path: 'cookies',
    loadChildren: () =>
      import('./cookies-page/cookies-page.module').then(
        (m) => m.CookiesPageModule
      ),
  },
  {
    path: 'legal',
    loadChildren: () =>
      import('./legal/legal.module').then((m) => m.LegalModule),
  },
  {
    path: 'articulo',
    loadChildren: () =>
      import('./academy-article/academy-article.module').then(
        (m) => m.AcademyArticleModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'ignore',
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
